import React, { ChangeEvent } from "react";

import TextField from "./TextField";

interface Props {
  disabled: boolean;
  email: boolean;
  event: SignupEvent;
  signup: Signup;
  handleChange: (attr: string) => (evt: ChangeEvent) => void;
}

export default function Accept(props: Props) {
  const { disabled, email, event, signup, handleChange } = props;
  return (
    <React.Fragment>
      {!email && <div className="field">Din epost: {signup.email}</div>}
      {email && (
        <TextField
          disabled={disabled}
          label="Din epost"
          id="signup-email"
          name="signup[email]"
          autoComplete="email"
          value={signup.email || ""}
          onChange={handleChange("email")}
          errors={signup.errors.email}
        />
      )}
      <TextField
        disabled={disabled}
        label="Ditt navn"
        id="signup-name"
        name="signup[name]"
        autoComplete="name"
        value={signup.name || ""}
        onChange={handleChange("name")}
        errors={signup.errors.name}
      />
      {event.companion_allowed && (
        <TextField
          disabled={disabled}
          label="Navn på følge"
          id="signup-companion"
          name="signup[companion]"
          value={signup.companion || ""}
          onChange={handleChange("companion")}
          errors={signup.errors.companion}
        />
      )}
      {event.extra_field_label && (
        <TextField
          disabled={disabled}
          label={event.extra_field_label}
          id="signup-extra-field"
          name="signup[extra_field]"
          value={signup.extra_field || ""}
          onChange={handleChange("extra_field")}
          errors={signup.errors.extra_field}
        />
      )}
      {event.selectable_options.length > 0 && (
        <div className="field radio-buttons">
          {event.selectable_options.map((o) => (
            <label key={o}>
              <input
                disabled={disabled}
                checked={signup.selected_option === o}
                name="signup[selected_option]"
                type="radio"
                value={o}
                onChange={handleChange("selected_option")}
              />
              {o}
            </label>
          ))}
        </div>
      )}
      <div className="field check-box terms">
        <label htmlFor="signup-consent">
          <input
            disabled={disabled}
            id="signup-consent"
            type="checkbox"
            name="signup[consent]"
            checked={signup.consent}
            value="1"
            onChange={handleChange("consent")}
          />
          {event.terms}
        </label>
      </div>
    </React.Fragment>
  );
}
