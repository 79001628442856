import { Controller } from "stimulus";

export default class extends Controller {
  declare readonly anchorTarget: HTMLAnchorElement;

  static get targets() {
    return ["anchor"];
  }

  initialize() {
    if (
      window.location.hash &&
      window.location.hash.substr(1) == this.anchorTarget.name
    ) {
      this.element.classList.add("expanded");
      this.anchorTarget.setAttribute("aria-expanded", "true");
    }
  }

  toggle(evt: Event) {
    evt.preventDefault();
    this.element.classList.toggle("expanded");

    this.anchorTarget.setAttribute(
      "aria-expanded",
      this.element.classList.contains("expanded") ? "true" : "false"
    );
  }
}
