import { Controller } from "stimulus";
import WindowAnimation from "../lib/WindowAnimation";

function setAriaExpanded(elem: Element, value: boolean) {
  elem.setAttribute("aria-expanded", value ? "true" : "false");
}

export default class extends Controller {
  navToggleTarget: HTMLButtonElement;
  searchInputTarget: HTMLInputElement;
  searchToggleTarget: HTMLAnchorElement;
  handle: number;

  static get targets() {
    return ["navToggle", "searchInput", "searchToggle"];
  }

  connect() {
    this.handle = WindowAnimation.register({
      scroll: (offset) => {
        if (offset > 100) {
          this.element.classList.add("scrolled");
        } else {
          this.element.classList.remove("scrolled");
          if (!this.element.classList.contains("searching")) {
            this.element.classList.remove("expanded");
          }
        }
      }
    });
  }

  disconnect() {
    WindowAnimation.clear(this.handle);
  }

  setExpanded(value: boolean) {
    setAriaExpanded(this.navToggleTarget, value);
    setAriaExpanded(this.searchToggleTarget, value);
  }

  toggleExpanded() {
    if (this.element.classList.contains("expanded")) {
      this.element.classList.remove("expanded");
      this.element.classList.add("contract");
      setTimeout(() => {
        this.element.classList.remove("contract");
      }, 250);
      this.element.classList.remove("searching");
      this.setExpanded(false);
    } else {
      this.element.classList.add("pre-expand");
      window.requestAnimationFrame(() => {
        this.element.classList.remove("pre-expand");
        this.element.classList.add("expanded");
        this.element.classList.add("searching");
        this.setExpanded(true);
      });
    }
  }

  showSearch(evt: Event) {
    evt.preventDefault();
    if (!this.element.classList.contains("searching")) {
      this.element.classList.add("searching");
      this.element.classList.add("expanded");
      this.setExpanded(true);
    }
    window.requestAnimationFrame(() => {
      this.searchInputTarget.focus();
    });
  }
}
